import api from "./api";
import TokenService from "./auth/token.service";

class EventsService
{
    BASE_PATH = '/moderator/event';

    //Polls
    getEventPolls(eventId, page) {
        return api
            .get(this.BASE_PATH + '/' + eventId + '/polls',{params: {
                page: page
            }});
    }

    getPanelPolls(pollId, page) {
        return api
            .get(this.BASE_PATH + '/panel/' + pollId + '/polls',{params: {
                page: page
            }});
    }

    getSingle(pollId) {
        return api
            .get(this.BASE_PATH + '/poll/' + pollId);
    }

    //Poll stats
    getPollStats(pollId) {
        return api
            .get(this.BASE_PATH + '/poll/' + pollId + '/stats');
    }
}

export default new EventsService();
